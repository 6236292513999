<template>
  <div class='container'>
    <div class="header">
      <div class="options" >
        <div :class="['option',activeIndex?'':'active']" @click="tabChange(0)">
          静态筛选场次
        </div>
        <div :class="['option',activeIndex?'active':'']" @click="tabChange(1)">
          静态筛查报告
        </div>
      </div>
      <div class="search">
        <van-icon name="search" size="4.8vw"/>
        <input type="text" v-model="listQuery.title" placeholder="请输入标题进行查找">
        <button @click="search">搜索</button>
      </div>
    </div>
    <div class="project-list" v-if="!activeIndex">
      <div class="total">
        <p>共<b style="margin: 0 1.3333vw;font-size: 2.9333vw;">{{recordCount}}</b>场 团体测评</p>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id" v-if="list.length>0">
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <p class="omit" style="flex: 1;font-weight: 550;">{{item.title}}</p>
          <img :src="item.imgExamQrcode" alt="" style="width: 6.4vw;height: 6.4vw;margin-left: 4vw;" @click="showImagePreview(item.imgExamQrcode)">
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;padding:5.3333vw 0;">
          <div class="message">
            <div class="message-num">{{item.totalNum}}</div>
            <p>布置人数</p>
          </div>
          <div class="message">
            <div class="message-num">{{item.testedNum}}</div>
            <p>已测人数</p>
          </div>
          <div class="message">
            <div class="message-num">{{item.totalNum-item.testedNum>0?item.totalNum-item.testedNum:0}}</div>
            <p>未测人数</p>
          </div>
        </div>
        <div style="display: flex;">
          <div class="time">
            <img src="@/assets/project/time.png" alt="" style="width: 3.2vw;height: 3.2vw;margin-right: 2.1333vw;">
            <p>{{item.startTime}} - {{item.endTime}}</p>
          </div>
        </div>
        <div class="status">
          <div style="display: flex;align-items: center;">
            <div style="width: .8vw;height: .8vw;border-radius: 50%;margin-right: 1.3333vw;background: #5EDBC9;"></div>
            <p style="color: #5EDBC9 ;">{{item.status===0?'未开始':item.status===1?'进行中':'已结束'}}</p>
          </div>
          <div style="display: flex;align-items: center;">
            <button v-if="item.status===0" @click="$router.push({path:'add',query:{examId:item.id}})">编辑</button>
            <button @click="$router.push({path:'success',query:{id:item.id}})">详情</button>
            <button @click="$router.push({path:'statistics',query:{id:item.id}})">统计</button>
          </div>
        </div>
      </div>
      <van-empty description="暂无团体测评" v-else></van-empty>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="report-list" v-else>
      <div class="total">
        <p>共<b style="margin: 0 1.3333vw;font-size: 2.9333vw;color: #666;">{{recordCount}}</b>条 测评报告</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style=";color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id" @click="$router.push({path:'reportDetail',query:{id:item.id}})" v-if="list.length>0">
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;flex-direction: column;justify-content: space-around;flex: 1;height: 13.8667vw;">
              <p><b style="font-size: 4vw;margin-right: 4vw">{{item.trueName}}</b><span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span> </p>
            </div>
            <div :class="['status',item.isBad===1?'warn':'']">
              <p style="font-size: 2.9333vw;">{{ !item.gotStatus?'未获取':item.isBad===1?'存在异常':'全部正常' }}</p>
            </div>
        </div>
        <p class="omit" style="color: #666;">测评场次: <span style="color: #999;">{{item.examTitle}}</span></p>
        <p style="margin-top: 1.3333vw;font-size: 3.2vw;color: #666;">学籍号：<span style="color: #999;">{{ item.sn }}</span></p>
      </div>
      <van-empty description="暂无团体测评" v-else></van-empty>
      <div ref="reportBottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="add-btn" @click="$router.push('add')">
      <!-- <p style="width: 100%;">发布</p>
      <p style="width: 100%;">测评</p> -->
    </div>
    <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'80%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 5.3333vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option">
            <!-- <p>学籍号</p> -->
            <input type="text" v-model="reportQuery.sn" placeholder="学籍号">
          </div>
          <div class="option" >
            <!-- <p>姓名</p> -->
            <input type="text" v-model="reportQuery.trueName" placeholder="姓名">
          </div>
          <div class="option">
            <!-- <p>性别</p> -->
            <div class="selectInput">
              <input type="text" v-model="genderName" @click="showPopup('gender')" placeholder="性别" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>校区</p> -->
            <div class="selectInput">
              <input type="text" v-model="areaName" @click="showPopup('area')" placeholder="校区" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>年级</p> -->
            <div class="selectInput">
              <input type="text" v-model="gradeName" @click="showPopup('grade')" placeholder="年级" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>班级</p> -->
            <div class="selectInput">
              <input type="text" v-model="reportQuery.studyClass" @click="showPopup('class')" placeholder="班级" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>测评场次</p> -->
            <div class="selectInput">
              <input type="text" v-model="examName" @click="showPopup('exams')" placeholder="测评场次" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>测评结果</p> -->
            <div class="selectInput">
              <input type="text" v-model="isBadName" @click="showPopup('isBad')" placeholder="测评结果" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
        </div>
        <div class="btns">
          <button @click="reset" style="color: #47D5B2;">重置</button>
          <button @click="filterSearch">搜索</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择性别"
        value-key="title"
        show-toolbar
        :columns="genderList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='gender'"
      />
      <van-picker
        title="选择校区"
        value-key="area"
        show-toolbar
        :columns="areasList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='area'"
      />
      <van-picker
        title="选择年级"
        value-key="studyClass"
        show-toolbar
        :columns="classList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='class'"
      />
      <van-picker
        title="选择班级"
        value-key="gradeName"
        show-toolbar
        :columns="gradeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='grade'"
      />
      <van-picker
        title="选择测评结果"
        value-key="title"
        show-toolbar
        :columns="isBadList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='isBad'"
      />
      <van-picker
        title="选择测评场次"
        value-key="title"
        show-toolbar
        :columns="examsScreenList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='exams'"
      />
    </van-popup>
    <Tabbar :activeIndex="1"></Tabbar>
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
import { ImagePreview } from 'vant';
import {bottomLoading} from '@/utils/util.js'
import {exams} from '@/api/exam.js'
import {reportList,gradeAndStudyClass,areasList} from '@/api/report.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      activeIndex:0,
      searchValue:'',
      isShow:false,
      isPopup:false,
      popupName:'',
      list:[],
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        title: ''
      },
      // 筛选栏目绑定数组
      genderList:[
        {id:-1,title:'全部'},
        {id:0,title:'女'},
        {id:1,title:'男'},
      ],
      // 筛选栏目展示数据
      genderName:null,
      areaName:null,
      gradeName:null,
      className:null,
      examName:null,
      isBadName:null,
      reportQuery:{
        pageNo: 1,
        pageSize: 10,
        projectId: null,
        grade: null,
        studyClass: null,
        trueName: null,
        gender: null,
        examId: null,
        area: null,
        export: null,
        isBad: null,
        sn: null
      },
      totalPageCount:0,
      recordCount:0,
      gradeList:[],
      areasList:[],
      examsScreenList:[],
      classList:[],
      isBadList:[{id:-1,title:'全部'},{id:0,title:'正常'},{id:1,title:'异常'},]
    }
  },
  created(){
    let {type} = this.$route.query
    if(type){
      type=Number(type)
      this.activeIndex=type
      this.tabChange(type)
    }else{
      this.tabChange(0)
      // this.getList()
    }
    // this.getList()
    this.getGradeOrClass()
    this.getAreasList()
    this.getExamsScreenList()
  },
  mounted(){
    // bottomLoading(this.$refs.bottom,this.loadNextPage)
    this.$nextTick(()=>{
      if(this.activeIndex===0){
        bottomLoading(this.$refs.bottom,this.loadNextPage)
      }else{
        bottomLoading(this.$refs.reportBottom,this.loadNextPage)
      }
    })
  },
  updated(){
  },
  methods:{
    async getList(){
      const formData={}
      for (const v in this.listQuery){
        if(this.listQuery[v]!==-1&&this.listQuery!==''){
          formData[v]=this.listQuery[v]
        }
      }
      const res = await exams(formData)
      this.list=res.data.list
      this.totalPageCount=res.data.totalPageCount
      this.recordCount=res.data.recordCount
    },
    async getReportList(){
      const formData={}
      for (const v in this.reportQuery){
        if(this.reportQuery[v]!==-1&&this.reportQuery!==''){
          formData[v]=this.reportQuery[v]
        }
      }
      // console.log('formData',formData);
      if(formData.studyClass==='全部'){
        formData.studyClass=null
      }
      const res = await reportList(formData)
      this.list=res.data.list
      this.totalPageCount=res.data.totalPageCount
      this.recordCount=res.data.recordCount
    },
    tabChange(index){
      this.activeIndex=index
      if(index===0){
        this.$nextTick(()=>{
          this.listQuery.pageNo=1
          this.getList()
          // bottomLoading(this.$refs.bottom,this.loadNextPage)
        })
      }else{
        this.$nextTick(()=>{
          this.reportQuery.pageNo=1
          this.getReportList()
          // bottomLoading(this.$refs.reportBottom,this.loadNextPage)
        })
      }
    },
    reset(){
      this.genderName=null,
      this.areaName=null,
      this.gradeName=null,
      this.className=null,
      this.examName=null,
      this.isBadName=null,
      this.reportQuery={
        pageNo: 1,
        pageSize: 10,
        projectId: null,
        grade: null,
        studyClass: null,
        trueName: null,
        gender: null,
        examId: null,
        area: null,
        export: null,
        isBad: null,
        sn: null
      },
      this.getReportList()
      this.isShow=false
    },
    search(){
      this.listQuery.pageNo=1
      this.getList()
      this.listQuery.title=''
    },
    filterSearch(){
      this.reportQuery.pageNo=1
      this.getReportList()
      this.isShow=false
    },
    showPopup(name){
      this.popupName=name
      this.isPopup=true
    },
    // 筛选确认
    onConfirm(value){
      if(this.popupName==='gender'){
        this.reportQuery.gender=value.id
        this.genderName=value.title
      }else if(this.popupName==='area'){
        this.reportQuery.area=value.id
        this.areaName=value.area
      }else if(this.popupName==='class'){
        this.reportQuery.studyClass=value.studyClass
      }else if(this.popupName==='grade'){
        this.reportQuery.grade=value.grade
        this.gradeName=value.gradeName
        this.reportQuery.studyClass=''
        const gradeList=this.gradeList.filter(item=>item.grade===value.grade)
        // console.log(gradeList);
        this.classList=gradeList[0].studyClassList
        this.classList.unshift({studyClass:'全部'})
      }else if(this.popupName==='isBad'){
        this.reportQuery.isBad=value.id
        this.isBadName=value.title
      }else if(this.popupName==='exams'){
        this.reportQuery.examId=value.id
        this.examName=value.title
      }
      this.isPopup=false
      // console.log(value);
    },
    async getAreasList(){
      const res = await areasList()
      res.data.areas.unshift({area: "全部", id: -1})
      this.areasList=res.data.areas
    },
    async getExamsScreenList(){
      const res = await exams({pageNo:1,pageSize:100000})
      res.data.list.unshift({id:-1,title:'全部'})
      this.examsScreenList=res.data.list
    },
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      res.data.grades.unshift({grade:-1,gradeName:'全部'})
      this.gradeList=res.data.grades
    },
    transformGrade(grade){
      let gradeName=''
      this.gradeList.forEach(item => {
        if(item.grade===grade){
          // console.log(grade,item.gradeName);
          gradeName=item.gradeName
        }
      })
      return gradeName
    },
    showImagePreview(imgUrl){
      const arr=[]
      arr.push(imgUrl)
      // console.log(imgUrl);
      ImagePreview({images:arr})
      // this.$imagePreview({images:arr});
    },
    async loadNextPage() {
      const formData={}
      if(this.listQuery.pageNo<this.totalPageCount&&this.activeIndex===0){
        this.listQuery.pageNo++
        // console.log(437,this.listQuery);
        for (const v in this.listQuery){
          if(this.listQuery[v]!==-1&&this.listQuery!==''){
            formData[v]=this.listQuery[v]
          }
        }
        // console.log(443,formData);
        const res = await exams(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }else if(this.reportQuery.pageNo<this.totalPageCount&&this.activeIndex===1){
        this.reportQuery.pageNo++
        for (const v in this.reportQuery){
          if(this.reportQuery[v]!==-1&&this.reportQuery!==''){
            formData[v]=this.reportQuery[v]
          }
        }
        const res = await reportList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // console.log('触发',this.listQuery.pageNo);
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 13.3333vw 3.2vw;
  background-color: #fff;
  min-height: 100%;
  .header{
    .options{
      display: flex;
      align-items: center;
      .option{
        padding: 0 4.5333vw;
        // width: 29.3333vw;
        height: 9.6vw;
        line-height: 9.6vw;
        text-align: center;
        // font-weight: 550;
        border-radius: 2.6667vw;
        background: #EFF9F8;
        &:last-child{
          margin-left: 5.3333vw;
        }
      }
      .option.active{
        // background-color: rgba(88, 88, 88, 1);
        font-weight: 550;
        color: #01C795 ;
      }
    }
    .search{
      margin-top: 4vw;
      padding: 0 .5333vw 0 4vw;
      display: flex;
      height: 9.6vw;
      align-items: center;
      background: #F5F7F9;
      border-radius: 13.3333vw;
      input{
        padding: 0 4vw;
        flex: 1;
        height: 100%;
        background: #F5F7F9;
        &::placeholder{
          color: #666 ;
          // font-weight: 550;
        }
      }
      button{
        padding: 0 6.6667vw;
        height: 8.5333vw;
        line-height: 8vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 13.3333vw;
        background-color: #5EDBC9 ;
      }
    }
  }
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4vw;
    margin: 4vw 0;
    color: #666 ;
  }
  .project-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .message{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 16vw;
        
        background: #EFF9F8;
        border-radius: 1.3333vw;
        margin-right: 5.3333vw;
        &:last-child{
          margin-right: 0;
        }
        p{
          font-size: 2.9333vw;
          color:#7AA7A0;
          margin-top: .5333vw; 
        }
        .message-num{
          font-size: 5.3333vw;
          font-weight: 550;
          color: #10CA9B ;
          // background-color: rgba(219, 219, 219, 1);
          text-align: center;
        }
      }
      .time{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2.9333vw;
        padding: 0 2.6667vw;
        height: 6.4vw;
        color: #797979 ;
        // border: .2667vw solid rgba(217, 217, 217, 1);
        border-radius: 1.0667vw;
        background-color: #FEF9F3;
      }
      .status{
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #5EDBC9 ;
        button{
          padding: 0 4vw;
          height: 6.9333vw;
          // line-height: 6.9333vw;

          border-radius: 1.3333vw;
          color: #fff;
          // border: .2667vw solid rgb(0, 0, 0);
          background-color: #5EDBC9;
          margin-right: 1.6vw;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .report-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .status{
        padding: 0 2%;
        display: flex;
        width: 10.6667vw;
        height: 10.6667vw;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 3.7333vw;
        border-radius: 1.3333vw;
        background: #E0FFFB;
        color: #5EDBC9;
        p{
          text-align: center;
        }
      }
      .status.warn{
        color: #FB6D3F;
        background: #FEF9F3;
      }
    }
  }
  .add-btn{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    right: 7.4667vw;
    bottom: 30.4vw;
    width: 18.1333vw;
    height: 18.1333vw;
    font-size: 3.7333vw;
    // border: .2667vw solid rgba(187, 187, 187, 1);
    border-radius: 50%;
    background-color: #fff;
    z-index: 99;
    background: url(../../assets/project/add-btn.png)center /100% no-repeat;
    p{
      text-align: center;
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 6.6667vw 13.8667vw 6.6667vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 4vw;
        color: #333;
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 5.3333vw;
          height: 12.2667vw;
          background: #F5F7F9;
          border-radius: 13.3333vw;
          // border: .2667vw solid rgba(187, 187, 187, 1);
          &::placeholder{
            color: #666;
          }
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      padding: 0 6.6667vw;
      width: 100%;
      height: 16vw;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 12.2667vw;
        line-height: 12.2667vw;
        background: #EFF9F8;
        border-radius: 13.3333vw;
        &:last-child{
          margin-left: 6.6667vw;
          background: #10CA9B;
        }
      }
    }
  }
}
</style>
import {request} from '@/utils/request'
// 场次列表
export function exams (params) {
  return request({
      url: '/orgExam/list',
      method: 'get',
      params
  })
}

// 场次详情
export function examDetail (params) {
  return request({
      url: '/orgExam/detail',
      method: 'get',
      params
  })
}

// 场次统计
export function examSum (params) {
  return request({
      url: '/orgExam/sum',
      method: 'get',
      params
  })
}
// 饼图统计
export function resultStatistics (params) {
  return request({
      url: '/orgExam/resultStatistics',
      method: 'get',
      params
  })
}

// 量表列表
export function getProjectList (params) {
  return request({
      url: '/orgExam/projects',
      method: 'get',
      params
  })
}
// 量表详情
export function projectDetail (params) {
  return request({
      url: '/orgExam/projectDetail',
      method: 'get',
      params
  })
}
<template>
    <van-tabbar
        v-model="active"
        active-color="#5edbc9"
        inactive-color="#171523"
        @change="tabChange"
    >
        <van-tabbar-item
            v-for="(tab, index) in tabs"
            :key="tab.iconPath"
            :badge="tab.badge ? tab.badge : ''"
        >
            <span style="font-size: 3.4667vw;">{{ tab.text }}</span>
            <template #icon>
                <img
                    :src="
                        active === index ? tab.selectedIconPath : tab.iconPath
                    "
                    style="width: 6.6667vw;height: 6.6667vw;"
                />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'Tabbar',
        props: {
            activeIndex: [String, Number]
        },
        created () {
            this.active = this.activeIndex
        },
        computed: {
            ...mapGetters(['tabs'])
        },
        data () {
            return {
                active: ''
            }
        },
        methods: {
            tabChange (active) {
                // console.log(active);
                this.$router.replace(this.tabs[active].pagePath)
            },
        }
    }

</script>
<style scoped lang="less">
</style>
import {request} from '@/utils/request'

// 报告列表
export function reportList (params) {
    return request({
        url: '/orgReport/list',
        method: 'get',
        params
    })
}

// 报告详情
export function reportDetail (params) {
    return request({
        url: '/orgReport/detail',
        method: 'get',
        params
    })
}

// 获取年级和班级
export function gradeAndStudyClass (params) {
    return request({
        url: '/orgProfile/getGradeAndStudyClass',
        method: 'get',
        params
    })
}

// 校区列表
export function areasList (params) {
    return request({
        url: '/orgProfile/areas',
        method: 'get',
        params
    })
}